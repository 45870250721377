/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import React, { Component, FunctionComponent, PropsWithChildren, ReactElement, ReactNode } from "react";
import { Grid, Tab, Tabs } from "@material-ui/core";
import { AuthWrapper, DeviceGroup, BackendFactory, Organization } from "@sade/data-access";
import InlineBox from "../ui/inline-box";
import TabPanel from "../ui/tab-panel";
import ViewAccessMethods from "../../ViewAccessMethods";
import UserList from "./components/user-list";
import accessControlled from "../access-control/access-controlled";
import { translations } from "../../generated/translationHelper";
import OtaManagement from "./components/ota-management";

interface Props {}

interface State {
  rootDeviceGroups: DeviceGroup[];
  organizationDeviceGroup?: DeviceGroup;
  selectedTab: number;
  homeOrganization?: Organization;
  selectedOrganization?: Organization;
  currentUserId?: string;
  searchFilter?: string;
  errorMsg?: string;
}

type TabPageProps = { currentPage: number; index: number };

const TabPage: FunctionComponent<TabPageProps> = (props: PropsWithChildren<TabPageProps>): ReactElement => {
  return (
    <TabPanel value={props.currentPage} index={props.index}>
      <InlineBox>{props.children}</InlineBox>
    </TabPanel>
  );
};

export class AdminView extends Component<Props, State> {
  private readonly organizationBackend = BackendFactory.getOrganizationBackend();

  public constructor(props: Props) {
    super(props);
    this.state = {
      rootDeviceGroups: [],
      selectedTab: 0,
    };
  }

  public async componentDidMount(): Promise<void> {
    const claims = await AuthWrapper.getCurrentAuthenticatedUserClaims();

    if (!claims) {
      throw new Error("Failed to resolve current user!");
    }

    const [homeOrganization, rootDeviceGroups] = await Promise.all([
      this.organizationBackend.getCurrentHomeOrganization(),
      BackendFactory.getBackend().getRootDeviceGroups(),
    ]);

    this.setState({
      rootDeviceGroups,
      homeOrganization,
      currentUserId: claims.userId,
      selectedOrganization: homeOrganization,
    });
  }

  private handleOrganizationSelected = (organization: Organization): void => {
    this.setState({
      selectedOrganization: organization,
    });
  };

  private handlePageChange = (event: React.ChangeEvent<unknown>, value: number): void => {
    console.log(`Changing page from ${this.state.selectedTab} to ${value}`);
    this.setState({ selectedTab: value });
  };

  private renderUsersList(): ReactNode {
    if (this.state.selectedOrganization && this.state.currentUserId) {
      return <UserList organization={this.state.selectedOrganization} currentUserId={this.state.currentUserId} />;
    }
  }

  private renderOtaManagement(): ReactNode {
    if (this.state.selectedOrganization && this.state.currentUserId) {
      return <OtaManagement organization={this.state.selectedOrganization} />;
    }
  }

  public render(): ReactNode {
    return (
      <div style={{ margin: "1rem" }}>
        <Grid container={true} spacing={1} justifyContent={"center"}>
          <Grid item={true} md={12} lg={10} xl={8}>
            <Tabs value={this.state.selectedTab} onChange={this.handlePageChange}>
              <Tab label={translations.admin.texts.users()} data-testid="users-tab" />
              <Tab label={translations.admin.texts.otaManagement()} data-testid="ota-management-tab" />
            </Tabs>
            <TabPage currentPage={this.state.selectedTab} index={0}>
              {this.renderUsersList()}
            </TabPage>
            <TabPage currentPage={this.state.selectedTab} index={1}>
              {this.renderOtaManagement()}
            </TabPage>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default accessControlled(AdminView, ViewAccessMethods.hasSuperAdminAccess);
