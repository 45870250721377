/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { __awaiter } from "tslib";
import { Auth } from "aws-amplify";
import { AWSThingGroup } from "../device/AWSThingGroup";
import { Device } from "../device/Device";
import { DeviceGroup } from "../device/DeviceGroup";
import { Service } from "./AppSyncClientProvider";
import { AppSyncClientFactory } from "./AppSyncClientFactory";
import { AuthWrapper } from "../auth/AuthWrapper";
import { AuthListener } from "../auth/AuthListener";
import { isDefined } from "../../common/Utils";
import { throwGQLError } from "../utils/Utils";
import { EntityRelationCache } from "../utils/EntityRelationCache";
import { AsyncCache } from "../utils/AsynCache";
import { UrlsQsEmbedGenerateDocument } from "../../generated/gqlStats";
import { DeviceGroupsCreateDocument, DeviceGroupsDevicesListDocument, DeviceGroupsGetByOrgDocument, DeviceGroupsGetDocument, DeviceGroupsListDocument, DevicesCountDocument, DevicesCreateDocument, DevicesDeviceGroupsListDocument, DevicesEncryptDocument, DevicesGetDocument, DevicesListAllDocument, DevicesSearchDocument, IntegrationsClearPinDocument, IntegrationsGetPinDocument, IntegrationsSetPinDocument, } from "../../generated/gqlDevice";
import { prefixlessId } from "../organization/Utils";
import { DeviceAccessSubscriptionManager } from "../device/DeviceAccessSubscriptionManager";
export function narrowDownAttributeTypes(attrs) {
    return attrs.map(({ key, value }) => ({ key, value: value !== null && value !== void 0 ? value : undefined }));
}
export default class AWSBackend {
    constructor(deviceFactory) {
        this.deviceFactory = deviceFactory;
        this.entityRelationCache = new EntityRelationCache();
        // TODO: it might be smarter to cache devices to DeviceFactory
        this.deviceCache = new AsyncCache();
        this.groupCache = new AsyncCache();
        this.deviceAccessListener = {
            accessRemoved: (deviceId) => __awaiter(this, void 0, void 0, function* () {
                yield this.deviceCache.delete(deviceId);
            }),
            accessGranted: (deviceId) => __awaiter(this, void 0, void 0, function* () {
                yield this.getDevice(deviceId);
            }),
        };
        this.authEventHandler = (event) => {
            if (event === "SignedIn") {
                DeviceAccessSubscriptionManager.instance.addListener(this.deviceAccessListener);
            }
            if (event === "SignedOut") {
                this.groupCache.clear();
                this.deviceCache.clear();
                this.entityRelationCache.clear();
                DeviceAccessSubscriptionManager.instance.removeListener(this.deviceAccessListener);
                this.rootGroupIds = undefined;
            }
            if (event === "TokenRefreshSucceeded") {
                console.log("Token refresh succeeded");
            }
            if (event === "TokenRefreshFailed") {
                console.error("Token refresh failed, logging out");
                AuthWrapper.logOut();
            }
        };
        this.authListener = new AuthListener(this.authEventHandler);
        this.fragmentIntoDeviceGroup = (fragment) => __awaiter(this, void 0, void 0, function* () {
            return new AWSThingGroup(this, {
                groupId: fragment.id,
                attributes: narrowDownAttributeTypes(fragment.attr),
            });
        });
        this.deviceFragmentIntoDevice = (fragment) => __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c, _d, _e, _f;
            const device = this.deviceFactory.createDevice(this, fragment.type, {
                deviceId: fragment.id,
                attributes: narrowDownAttributeTypes(fragment.attr),
                controlLevel: fragment.controlLevel,
                groupIds: (_b = (_a = fragment.groupIds) === null || _a === void 0 ? void 0 : _a.filter((s) => s !== null)) !== null && _b !== void 0 ? _b : undefined,
            });
            if (device) {
                // While this is pretty bad for performance, it was already done everywhere anyways.
                // It should actually probably be part of the factory instead.
                let stateInfo = undefined;
                if (fragment.state) {
                    stateInfo = {
                        deviceId: fragment.state.deviceId,
                        connectionState: fragment.state.connectionState
                            ? {
                                connected: fragment.state.connectionState.connected,
                                updatedTimestamp: fragment.state.connectionState.updatedTimestamp,
                            }
                            : undefined,
                        desired: (_c = fragment.state.desired) !== null && _c !== void 0 ? _c : undefined,
                        reported: (_d = fragment.state.reported) !== null && _d !== void 0 ? _d : undefined,
                        timestamp: (_e = fragment.state.timestamp) !== null && _e !== void 0 ? _e : undefined,
                        version: (_f = fragment.state.version) !== null && _f !== void 0 ? _f : undefined,
                    };
                }
                yield device.init(stateInfo);
                return device;
            }
        });
        this.integrationsSetPin = (pin, c2cType) => __awaiter(this, void 0, void 0, function* () {
            var _g, _h;
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
            const response = yield client.mutate(IntegrationsSetPinDocument, { pin, c2cType });
            return (_h = (_g = response.data) === null || _g === void 0 ? void 0 : _g.integrationsSetPin) !== null && _h !== void 0 ? _h : false;
        });
        this.integrationsClearPin = (c2cType) => __awaiter(this, void 0, void 0, function* () {
            var _j, _k;
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
            const response = yield client.mutate(IntegrationsClearPinDocument, { c2cType });
            return (_k = (_j = response.data) === null || _j === void 0 ? void 0 : _j.integrationsClearPin) !== null && _k !== void 0 ? _k : false;
        });
        this.integrationsGetPin = (c2cType) => __awaiter(this, void 0, void 0, function* () {
            var _l, _m;
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
            const response = yield client.query(IntegrationsGetPinDocument, { c2cType }, { fetchPolicy: "network-only" });
            return (_m = (_l = response.data) === null || _l === void 0 ? void 0 : _l.integrationsGetPin) !== null && _m !== void 0 ? _m : undefined;
        });
    }
    static getOrganization(group) {
        return __awaiter(this, void 0, void 0, function* () {
            let org = group ? group.getOrganization() : undefined;
            if (!org || org.length === 0) {
                const claims = yield AuthWrapper.getCurrentAuthenticatedUserClaims();
                org = claims === null || claims === void 0 ? void 0 : claims.homeOrganizationId;
            }
            if (!org) {
                throw new Error("No organization available");
            }
            return org;
        });
    }
    getQsEmbedUrl(openIdToken, dashboardId) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const user = yield Auth.currentAuthenticatedUser();
                const variables = {
                    request: {
                        dashboardId,
                        emailAddress: user.username,
                        openIdToken,
                        sessionName: user.username,
                        undoRedoDisabled: true,
                        resetDisabled: true,
                        sessionLifetimeInMinutes: 600,
                    },
                };
                const client = AppSyncClientFactory.createProvider().getTypedClient(Service.STATS);
                const embedUrlResponse = yield client.query(UrlsQsEmbedGenerateDocument, variables);
                return (_b = (_a = embedUrlResponse.data.urlsQsEmbedGenerate) === null || _a === void 0 ? void 0 : _a.embedUrl) !== null && _b !== void 0 ? _b : undefined;
            }
            catch (error) {
                console.error("getQsEmbedUrl: ", error);
            }
        });
    }
    createDevice(id, type, profileId) {
        const createDevice = () => __awaiter(this, void 0, void 0, function* () {
            var _a;
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
            try {
                const response = yield client.mutate(DevicesCreateDocument, {
                    payload: {
                        deviceId: id,
                        type: type,
                        attributes: [{ key: "profileId", value: profileId }],
                    },
                });
                if ((_a = response.data) === null || _a === void 0 ? void 0 : _a.devicesCreate) {
                    return this.deviceFragmentIntoDevice(response.data.devicesCreate);
                }
            }
            catch (err) {
                console.error("Failed to create a new device.", err);
            }
        });
        return this.deviceCache.get(id, createDevice);
    }
    getDevice(id, skipCache = false) {
        return __awaiter(this, void 0, void 0, function* () {
            const fetchDevice = () => __awaiter(this, void 0, void 0, function* () {
                const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
                try {
                    const response = yield client.query(DevicesGetDocument, {
                        deviceId: id,
                    }, { fetchPolicy: skipCache ? "network-only" : "cache-first" });
                    if (response.data.devicesGet) {
                        return this.deviceFragmentIntoDevice(response.data.devicesGet);
                    }
                }
                catch (err) {
                    console.error("Failed to fetch device.", err);
                }
            });
            if (skipCache)
                yield this.deviceCache.delete(id);
            return this.deviceCache.get(id, fetchDevice);
        });
    }
    getDeviceGroup(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const fetchDeviceGroup = () => __awaiter(this, void 0, void 0, function* () {
                const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
                const response = yield client.query(DeviceGroupsGetDocument, {
                    groupId: id,
                });
                if (response.data.deviceGroupsGet) {
                    return this.fragmentIntoDeviceGroup(response.data.deviceGroupsGet);
                }
            });
            return this.groupCache.get(id, fetchDeviceGroup);
        });
    }
    getOrganizationDevices(organizationId) {
        return __awaiter(this, void 0, void 0, function* () {
            const query = `attributes.organization: "${organizationId}"`;
            return yield this.searchDevices(query);
        });
    }
    findDeviceGroupByOrganization(organizationId) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const fetchDeviceGroup = () => __awaiter(this, void 0, void 0, function* () {
                const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
                const response = yield client.query(DeviceGroupsGetByOrgDocument, {
                    organizationId: organizationId,
                }, {
                    fetchPolicy: "network-only",
                });
                if (response.data.deviceGroupsGetByOrg) {
                    return this.fragmentIntoDeviceGroup(response.data.deviceGroupsGetByOrg);
                }
            });
            const group = (_a = (yield this.groupCache.find((iter) => {
                return iter.getOrganization() === organizationId;
            }))) !== null && _a !== void 0 ? _a : (yield fetchDeviceGroup());
            if (group) {
                this.groupCache.set(group.getId(), group);
            }
            return group;
        });
    }
    findDeviceGroupByName(name, organizationId) {
        return __awaiter(this, void 0, void 0, function* () {
            const orgString = organizationId ? prefixlessId(organizationId) : organizationId;
            const selectByOrganization = (group) => {
                if (!orgString)
                    return true;
                const groupOrg = group.getOrganization();
                if (!groupOrg)
                    return true;
                return orgString.startsWith(prefixlessId(groupOrg));
            };
            const roots = yield this.getRootDeviceGroups();
            let selected = roots.filter(selectByOrganization);
            while (selected.length > 0) {
                const match = selected.find((group) => group.getLabel() === name);
                if (match)
                    return match;
                selected = (yield Promise.all(selected.map((group) => group.getGroups()))).flat();
            }
        });
    }
    getRootDeviceGroups() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.rootGroupIds) {
                const groups = yield Promise.all(this.rootGroupIds.map((id) => this.getDeviceGroup(id)));
                const filteredGroups = groups.filter(isDefined);
                if (groups.length !== filteredGroups.length) {
                    console.error("Invalid root group id cache state, adjusting");
                    this.rootGroupIds = filteredGroups.map((group) => group.getId());
                }
                return filteredGroups;
            }
            const groups = yield this.getDeviceGroups();
            if (groups.length > 0) {
                this.rootGroupIds = groups.map((group) => group.getId());
            }
            return groups;
        });
    }
    getAllDeviceGroups() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.getDeviceGroups({ recursive: true });
        });
    }
    getDeviceGroups({ parent, recursive = false, } = {}) {
        var _a, _b, _c, _d, _e;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                let nextToken = null;
                let groupFragments = [];
                const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
                do {
                    const groupListResponse = yield client.query(DeviceGroupsListDocument, {
                        recursive,
                        includeAttributes: true,
                        parentGroupId: parent === null || parent === void 0 ? void 0 : parent.getId(),
                        nextToken,
                    }, {
                        fetchPolicy: "network-only",
                    });
                    // for some reason, typescript gets trapped into a circular inference hell, if the type of the
                    // nextToken is not respecified
                    nextToken = ((_c = (_b = (_a = groupListResponse.data) === null || _a === void 0 ? void 0 : _a.deviceGroupsList) === null || _b === void 0 ? void 0 : _b.nextToken) !== null && _c !== void 0 ? _c : null);
                    groupFragments = groupFragments.concat((_e = (_d = groupListResponse.data.deviceGroupsList) === null || _d === void 0 ? void 0 : _d.deviceGroups) !== null && _e !== void 0 ? _e : []);
                } while (nextToken);
                if (groupFragments.length === 0) {
                    return [];
                }
                const groups = yield this.cacheFragments(this.groupCache, groupFragments, this.fragmentIntoDeviceGroup);
                if (parent && !recursive) {
                    groups.forEach((group) => this.entityRelationCache.link(parent, group, { parentId: parent.getId() }));
                }
                else if (recursive) {
                    console.warn("Cannot establish parent-child relationship between device groups in recursive calls");
                }
                return groups;
            }
            catch (error) {
                console.error("getDeviceGroups", error);
                return [];
            }
        });
    }
    createDeviceGroup(params) {
        var _a, _b, _c, _d, _e;
        return __awaiter(this, void 0, void 0, function* () {
            if (params.parentGroup && !AWSThingGroup.instanceOf(params.parentGroup)) {
                throw new Error("Invalid DeviceGroup implementation for parent group");
            }
            const org = (_a = params.organizationId) !== null && _a !== void 0 ? _a : (yield AWSBackend.getOrganization(params.parentGroup));
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
            const groupResponse = yield client.mutate(DeviceGroupsCreateDocument, {
                groupId: params.displayName,
                parentGroupId: (_c = (_b = params.parentGroup) === null || _b === void 0 ? void 0 : _b.getId()) !== null && _c !== void 0 ? _c : null,
                organizationId: org,
            });
            if (!((_d = groupResponse.data) === null || _d === void 0 ? void 0 : _d.deviceGroupsCreate)) {
                console.error("Failed to create group, backend response empty: " + JSON.stringify(groupResponse.errors));
                throwGQLError(groupResponse);
            }
            const newGroup = yield this.fragmentIntoDeviceGroup(groupResponse.data.deviceGroupsCreate);
            this.groupCache.set(newGroup.getId(), newGroup);
            if (!params.parentGroup) {
                this.rootGroupIds = ((_e = this.rootGroupIds) !== null && _e !== void 0 ? _e : []).concat(newGroup.getId());
            }
            else {
                this.entityRelationCache.link(params.parentGroup, newGroup, { parentId: params.parentGroup.getId() });
            }
        });
    }
    searchDevices(query) {
        var _a, _b, _c, _d, _e, _f;
        return __awaiter(this, void 0, void 0, function* () {
            // TODO: should we have query-specific cache?
            console.info(`Searching for devices with query ${query}`);
            try {
                const deviceFragments = [];
                let nextToken = null;
                const appSyncClient = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
                do {
                    const searchDevicesResponse = yield appSyncClient.query(DevicesSearchDocument, {
                        query,
                        nextToken,
                    }, {
                        fetchPolicy: "network-only",
                    });
                    nextToken = ((_c = (_b = (_a = searchDevicesResponse.data) === null || _a === void 0 ? void 0 : _a.devicesSearch) === null || _b === void 0 ? void 0 : _b.nextToken) !== null && _c !== void 0 ? _c : null);
                    deviceFragments.push(...((_f = (_e = (_d = searchDevicesResponse.data) === null || _d === void 0 ? void 0 : _d.devicesSearch) === null || _e === void 0 ? void 0 : _e.devices) !== null && _f !== void 0 ? _f : []));
                } while (nextToken);
                return this.cacheFragments(this.deviceCache, deviceFragments, this.deviceFragmentIntoDevice);
            }
            catch (error) {
                console.error("searchDevices", error);
                return [];
            }
        });
    }
    countDevices(query) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            console.info(`Counting devices with query ${query}`);
            const appSyncClient = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
            const countDevicesResponse = yield appSyncClient.query(DevicesCountDocument, {
                query,
            }, {
                fetchPolicy: "network-only",
            });
            if (!((_a = countDevicesResponse.data) === null || _a === void 0 ? void 0 : _a.devicesCount))
                throwGQLError(countDevicesResponse, "Getting devices count failed");
            return (_b = countDevicesResponse.data) === null || _b === void 0 ? void 0 : _b.devicesCount;
        });
    }
    getAccessibleDevices() {
        var _a, _b, _c, _d, _e, _f;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const deviceFragments = [];
                let nextToken = null;
                const appSyncClient = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
                do {
                    const devicesListAllResponse = yield appSyncClient.query(DevicesListAllDocument, {
                        nextToken,
                    }, {
                        fetchPolicy: "network-only",
                    });
                    nextToken = ((_c = (_b = (_a = devicesListAllResponse.data) === null || _a === void 0 ? void 0 : _a.devicesListAll) === null || _b === void 0 ? void 0 : _b.nextToken) !== null && _c !== void 0 ? _c : null);
                    deviceFragments.push(...((_f = (_e = (_d = devicesListAllResponse.data) === null || _d === void 0 ? void 0 : _d.devicesListAll) === null || _e === void 0 ? void 0 : _e.devices) !== null && _f !== void 0 ? _f : []));
                } while (nextToken);
                return this.cacheFragments(this.deviceCache, deviceFragments, this.deviceFragmentIntoDevice);
            }
            catch (error) {
                console.error("getAccessibleDevices", error);
                return [];
            }
        });
    }
    encryptWithDeviceCertificate(message, certificateArn) {
        return __awaiter(this, void 0, void 0, function* () {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
            const response = yield client.query(DevicesEncryptDocument, { certificateArn, message });
            if (!response.data.devicesEncrypt) {
                throwGQLError(response, "Encryption failed");
            }
            return response.data.devicesEncrypt;
        });
    }
    getDeviceGroupDevices(group) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
                let nextToken = null;
                const deviceFragments = [];
                do {
                    const deviceIdListResponse = yield client.query(DeviceGroupsDevicesListDocument, {
                        groupId: group.getId(),
                        nextToken,
                    }, {
                        fetchPolicy: "network-only",
                    });
                    // cast is required to avoid cyclic type inference on response type
                    nextToken = ((_b = (_a = deviceIdListResponse.data.deviceGroupsDevicesList) === null || _a === void 0 ? void 0 : _a.nextToken) !== null && _b !== void 0 ? _b : null);
                    deviceFragments.push(...((_d = (_c = deviceIdListResponse.data.deviceGroupsDevicesList) === null || _c === void 0 ? void 0 : _c.devices) !== null && _d !== void 0 ? _d : []));
                } while (nextToken);
                // no need to generate group<->device links here, since the deviceFragmentIntoDevice call already generates the links
                const devices = yield this.cacheFragments(this.deviceCache, deviceFragments, this.deviceFragmentIntoDevice);
                devices.forEach((device) => this.entityRelationCache.link(device, group));
                return devices;
            }
            catch (error) {
                console.error("getDeviceGroupDevices", error);
                return [];
            }
        });
    }
    /////
    /// AWSBackend specific public methods
    /////
    linkDeviceGroupsForDevice(device) {
        var _a, _b, _c, _d, _e, _f;
        return __awaiter(this, void 0, void 0, function* () {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
            let nextToken = null;
            const groupFragments = [];
            try {
                do {
                    const response = yield client.query(DevicesDeviceGroupsListDocument, {
                        deviceId: device.getId(),
                        nextToken,
                    });
                    nextToken = ((_c = (_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.devicesDeviceGroupsList) === null || _b === void 0 ? void 0 : _b.nextToken) !== null && _c !== void 0 ? _c : null);
                    groupFragments.push(...((_f = (_e = (_d = response.data) === null || _d === void 0 ? void 0 : _d.devicesDeviceGroupsList) === null || _e === void 0 ? void 0 : _e.deviceGroups) !== null && _f !== void 0 ? _f : []));
                } while (nextToken);
                const groups = (yield this.cacheFragments(this.groupCache, groupFragments, this.fragmentIntoDeviceGroup));
                groups.forEach((group) => this.entityRelationCache.link(device, group));
            }
            catch (error) {
                console.error("linkDeviceGroupsForDevice", error);
            }
        });
    }
    removeLocal(thing) {
        return __awaiter(this, void 0, void 0, function* () {
            if (Device.instanceOf(thing)) {
                yield this.deviceCache.delete(thing.getId());
            }
            else if (DeviceGroup.instanceOf(DeviceGroup)) {
                yield this.groupCache.delete(thing.getId());
            }
            this.entityRelationCache.remove(thing);
        });
    }
    getSupportedDeviceTypes() {
        return this.deviceFactory.listDeviceTypes();
    }
    /**
     * Takes a collection of fragments, which are either
     * - if id matches something in cache, replaced with the cached entity
     * - converted into the desired entity, and then cached
     *
     * @param cache
     *    an AsyncCache into which to store the converted fragments
     * @param fragments
     *    list of fragments to go through
     * @param fragmentConverter
     *    method for converting fragment into the desired entity type
     * @private
     */
    cacheFragments(cache, fragments, fragmentConverter) {
        return __awaiter(this, void 0, void 0, function* () {
            const results = yield Promise.all(fragments.map((fragment) => cache.get(fragment.id, () => fragmentConverter(fragment))));
            return results.filter(isDefined);
        });
    }
}
